import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {getTokenFromLocalStorage, updateText} from "../../helper/util";
import userStore from "../../stores/userStore";
import BlogArticleCardComponent from "../blog/BlogArticleCardComponent";
import history from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import validator from "validator";
//import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

const LINKS = [
    {
        name: "Aktuelles",
        path: "/current"
    }, {
        name: "Förderungen",
        path: "/promotions"
    },
    {
        name: "Mitwirken",
        path: "/engage"
    }, {
        name: "Über uns",
        path: "/about"
    }, {
        name: "DREPS Stiftung",
        path: "/dreps"
    }
];

const PAGE_LINK = "Suche";

@inject("stores")
@observer
class SearchPage extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true,
                editState: false,
                search: "",
                editValue: "",
                articles: []
            }
    }

    //check xss
    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        if (typeof this.props.location.search !== "undefined" &&
            this.props.location.search.length > 1) {
            this.setState({...this.state, search: this.props.location.search.substring(1)});
            this.getSearchItems();
        }
        window.scrollTo(0, 0);
    }

    getSearchItems() {
        console.log(this.state.search)
        // Get Blog dates from Server
        fetch(config.BASE_URL + 'blog/search/' + this.props.location.search.substring(1), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(res => {
            res.json().then(data => {
                let articles = data.articles;
                //Sort Articles
                articles.sort(function (a, b) {
                    a = new Date(a.createdAt);
                    b = new Date(b.createdAt);
                    return a > b ? -1 : a < b ? 1 : 0;
                });
                this.setState({...this.state, articles: articles});
            }).catch(error => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    handleChange(event, data) {
        this.setState({...this.state, editValue: data.value})
    }

    handleSearchChange(event, data) {
        this.setState({...this.state, search: data.value})
    }

    uploadThumbnailImage(event) {
        let self = this;
        const data = new FormData();
        data.append('image', event.target.files[0]);
        fetch(config.BASE_URL + 'images', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    console.log("response json: " + json.toString());
                    self.setState({...self.state, editValue: json.link})
                });
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    getShowLinks() {
        let showFilteredLinks = [];

        for (let i = 0; i < LINKS.length; i++) {
            let link = LINKS[i];

            if (link.name.toLowerCase().includes(this.state.search.toLowerCase())) {
                showFilteredLinks.push(
                    <Grid.Column computer={12} tablet={14} mobile={16} centered>
                        <Segment fluid className={"card-shadow stretched-card no-border"}>
                            <Grid>
                                <Grid.Row className={"no-padding"}>
                                    <Grid.Column width={13} streched verticalAlign={"middle"}>
                                        <h3>{link.name}</h3>
                                    </Grid.Column>
                                    <Grid.Column width={3} onClick={() => history.push(link.path)}
                                                 className={"download-right"}>
                                        <Icon name={"arrow right"} size={"huge"}/>
                                        <p>ÖFFNEN</p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                );
            }
        }

        return showFilteredLinks;
    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";
        const defaultImage = "https://www.buergerstiftung-kreis-rv.de/images/Buergerstiftung.jpg";

        let imageLinkId = "search-title-image-id";
        const textStore = this.state.textStore

        let textToShow = typeof textStore[imageLinkId] === "undefined" ? {content: ""} : textStore[imageLinkId]['de-DE'];

        let style = {backgroundImage: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + textToShow.content + "')"};
        if (textToShow.content === "") {
            style = {background: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + defaultImage + "')"};
        }

        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let editDiv = <div/>;
        if (displayType === "admin") {
            if (this.state.editState) {
                editDiv = <div>
                    <Form>
                        <Form.Input value={this.state.editValue} onChange={this.handleChange.bind(this)}/>
                    </Form>
                    <br/>
                    <input type="file" id="file" className="inputfile"
                           onChange={this.uploadThumbnailImage.bind(this)}
                           accept="image/*"/>
                    <label htmlFor="file" className={"input-label"}>
                        Hochladen
                    </label>

                    <Button.Group>
                        <Button color="green" onClick={() => {
                            updateText(imageLinkId, this.state.editValue, "de-DE", 'search').then(isSuccess => {
                                if (isSuccess) {
                                    fetchTextById(imageLinkId).then(text => {
                                        textStore[imageLinkId] = text[imageLinkId];
                                        this.setState({...this.state, editState: false, editValue: ""});
                                    })
                                }
                            });
                        }}>Speichern</Button>
                        <Button color="red" onClick={() => {
                            this.setState({...this.state, editState: false});
                        }}>Abbrechen</Button>

                    </Button.Group>
                </div>
            } else {
                editDiv = <div>
                    {textToShow.content}
                    {textToShow.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                               color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.setState({...this.state, editState: true, editValue: textToShow});
                    }}/>
                </div>
            }
        }

        //this weird construct so on computer there are always two widths padding for the sides
        let showArticles = [];
        for (let i = 0; i < this.state.articles.length; i++) {
            if (i % 3 === 0) {
                showArticles.push(<Grid.Column width={2} only={"computer"} key={i + "x"}>
                </Grid.Column>);
                if (i !== 0) {
                    showArticles.push(<Grid.Column width={2} only={"computer"} key={i + "xy"}>
                    </Grid.Column>);
                }
            }
            let article = this.state.articles[i];
            showArticles.push(
                <Grid.Column computer={4} tablet={8} mobile={16} key={i}>
                    <BlogArticleCardComponent
                        titleText={article.header}
                        teaserText={article.description}
                        imageLink={article.thumbnail}
                        buttonLink={article.id}
                        dateText={article.createdAt}
                    />
                </Grid.Column>
            );
        }

        let showLinks = this.getShowLinks();


        return (
            <Grid>
                <Grid.Row
                    centered
                    className={"page-title-image"}
                    style={style}>
                    <Grid.Column width={10} textAlign={"center"}>
                        {editDiv}
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h1>

                            <EditSimpleValueComponent textStore={this.state.textStore} id={"search-header-id"}
                                                      namespace={'search'}/>
                        </h1>
                        <Form onSubmit={() => this.getSearchItems()}>
                            <Form.Input icon={<Icon name={"search"} link
                                                    onClick={() => this.getSearchItems()}/>}
                                        placeholder='Suchen...'
                                        fluid size='massive'
                                        className={"search-bar"}
                                        value={this.state.search}
                                        onChange={this.handleSearchChange.bind(this)}/>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={14} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Ihr Suchergebnis</h2>
                        <p className={"text-block"}>
                            {this.state.articles.length} Resultate für "{this.state.search}"
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {showArticles}
                </Grid.Row>
                <Grid.Row centered>
                    {showLinks}
                </Grid.Row>
            </Grid>

        );
    }
}

export default SearchPage